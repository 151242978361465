import { Component, OnInit, Input } from '@angular/core';
import { NumberValueAccessor } from '@angular/forms';
import { CheckpointsService } from '../../services/checkpoints.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {

  
  constructor(public checkpointsService:CheckpointsService) {
   
   }

  ngOnInit(): void {
    
  }

  

}
