import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserValidationService {
  userValidated:boolean;
  validationQuestions = new Array();
  validationAnswers = new Array();
  captchaResponse = ""
  jsonUser = "";
  
  constructor(private HttpService: HttpService) { 
    this.userValidated = false;
  }
  
  mergeUserArray(){
    this.jsonUser = JSON.stringify({ CaptchaResponse: this.captchaResponse, fullname: this.validationAnswers[0], caseReference: this.validationAnswers[1], registrationNumber: this.validationAnswers[2], ValidatedEnvironment: environment["ValidatedEnvironment"]});
  }

  IsUserValidated(){
    return this.userValidated;
  }

  ValidateUser(){
    this.mergeUserArray();
    return this.HttpService.validateUser(this.jsonUser);
    }


}
