import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../_modal';



@Component({
  selector: 'app-video-buttons',
  templateUrl: './video-buttons.component.html',
  styleUrls: ['./video-buttons.component.css']
})
export class VideoButtonsComponent implements OnInit {
  
  @Input() url!: string;
  @Input() id!: string;
  @Input() writtenExplanation!: string;
  @Input() modal=false;
  @Input() noThankYouRequired=true;
  @Input() noVideoRequired=true;
  @Input() proceedButtonText: string = "Proceed";
  @Input() displayText = true;
  @Input() downArrow = false;

  @Output() answered = new EventEmitter<any>();
  @Output() noThankYou = new EventEmitter<any>();

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.updateCheckboxComponent();
    }, 10);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string){
    this.modalService.close(id);
    this.nextQuestion();
  }

  nextQuestion(){
    this.answered.emit();
  }

  noThankYouClicked(){
    this.noThankYou.emit();
    if(!this.modal){
      this.nextQuestion();
    }
  }
  updateCheckboxComponent(){
    
  }
}
