import { ApplicationRef, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserValidationService } from '../../services/user-validation.service';
import { ModalService } from '../_modal';
import { HttpService } from '../../services/http.service';
import { CheckpointsService } from '../../services/checkpoints.service';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.css']
})
export class QuestionFormComponent implements OnInit {

  validUser = false;
  isSubmitted = false;
  otherValueAnswer = "Other / none of the above";

  personalDetailsQuestionsList = [
    "Name",
    "HF Reference Number",
    "Vehicle Registration Number",
    "Email address",
    "Telephone number"
  ];
  personalDetailsAnswersList = new Array(this.personalDetailsQuestionsList.length).fill("");

  
  questionList = [
    ["YOUR DETAILS","title"],
    ["There is a section in the letter we have sent to you with the title “Your Details”. This contains the accident date, accident location, other party details and accident circumstances.","message","2"],
    ["It is very important that this information is correct as when we issue Court proceedings the above facts will be included in a Court document called the “Particulars of Claim”. We need to certify that you believe the facts in the Particulars of Claim are true with what is called a “Statement of Truth“. We are required to inform you that proceedings may be brought for contempt of court if you do not honestly believe that the facts stated in the Particulars of Claim are true.","message","3"],
    ["Would you like to find out more about this before we continue?","video","https://player.vimeo.com/video/651890461?h=3a258cc859&autoplay=1¦4¦In the letter we sent to you, there is a section with the title “Your details”. This usually includes the accident date, accident location, other party details and accident circumstances. This information was provided to us by your insurers. It is very important that this information is correct. When we issue Court proceedings this information will be put into a court document called the “Particulars of Claim”. We need to certify that you believe the facts in the Particulars of Claim are true with what is called a Statement of Truth. We are obliged to inform you that proceedings may be brought for contempt of court if you do not honestly believe that the facts stated in the Particulars of Claim are true. You have the opportunity to amend or add to any of these details now. If you want to change anything, or add anything, please do so now."],

    ["Do you need to amend any of the information already in the “Your details“ section of the letter or would you like to add any further information to those details?","button","Yes¦5|No¦13"],
    ["Would you like to amend the accident date?","button","Yes¦6|No¦7"],
    ["Accident date","date","7"],
    ["Would you like to amend the accident location?","button","Yes¦8|No¦9"],
    ["Accident location information","big-text","Type here...¦9"],
    ["Would you like to amend the other party details?","button","Yes¦10|No¦11"],
    ["Other party details","big-text","Type here...¦11"],
    ["Would you like to amend or add any further information about the accident circumstances?","button","Yes¦12|No¦13"],
    ["Accident circumstance details","big-text","Type here...¦13"],

    ["Other Party Details","title"],
    ["We know you have provided some information to your insurance company but in the event we issue court proceedings it is important we validate the information to ensure the court claim form is correct.","message","15"],
    ["Please can you provide us with the details for the Other driver","button","Yes¦16|No, I don't know their details¦22"],
    ["Title:","text","Type here...¦17"],
    ["First Name:","text","Type here...¦18"],
    ["Surname:","text","Type here...¦19"],
    ["Other vehicle make:","text-nonrequired","Type here...¦20"],
    ["Other vehicle model:","text-nonrequired","Type here...¦21"],
    ["Other vehicle registration number:","text-nonrequired","Type here...¦22"],

    ["Uninsured Losses Explained","title"],
    ["We're now going to find out whether you've got any uninsured losses that need to be included with the claim we're making. For further information about what uninsured losses are please see below.","video","https://player.vimeo.com/video/501185434?h=a21ae76f6f&autoplay=1¦24¦Uninsured losses are expenses you have incurred or money you have paid out as a result of your accident, which are not covered by your own policy of insurance. Typical losses are your policy excess, credit hire, personal injury, loss of earnings, postage charges and telephone calls. If you have incurred any of these expenses/costs, then please provide us with details of the same when completing this process. If you have receipts to support the costs you have incurred we will need you to send these to us. If you do have any uninsured losses it is important that you provide us with details of them so that we can include them within the claim we are making on behalf of your motor insurers."],
    
    ["Hire Vehicle","title"],
    ["Next, we want to know if you have a claim for a hire vehicle. For further information about what this means, please see below.","video","https://player.vimeo.com/video/501185492?h=2968d92724&autoplay=1¦26¦Credit hire is the supply of a like for like vehicle on a credit basis. Ordinarily the credit hire company will contact you following your accident and they will arrange to drop a vehicle off for you and you will sign a credit agreement. Auxillis, Kindertons and Enterprise are often instructed to provide credit hire vehicles. If you have had a credit hire vehicle then it is imperative that you provide us with details of the hire company so we can ensure that the hire charges are included within the claim we make. If you were given a courtesy vehicle from the repairing garage then there will not be any charges to recover."],
    ["Did you have a hire vehicle?","button","Yes¦27|No¦32"],
    ["Do you know the name of the hire company?","button","Yes¦28|No¦30"],
    ["Hire company name:","text","Type name here...¦30"],
    ["","",""],
    ["Do you know their reference number?","button","Yes¦31|No¦32"],
    ["Hire company reference number:","text","Type reference here...¦32"],
    
    ["Do you have an injury claim?","title"],
    ["Next we need to know if you have made, or intend to make a claim for personal injury. For further information about what this means, please see below.","video","https://player.vimeo.com/video/501185556¦34¦The only reason we need to know who your personal injury claim is with is so we can contact them to ensure that neither of us take any action that would prejudice your claim. Your claim for injury would remain with your instructed solicitor and we would not look to deal with that aspect of your claim."],
    ["Have you made, or do you intend to make, a personal injury claim as a result of this incident?","button","Yes¦35|No¦36"],
    ["Which of the following best describes the status of your personal injury claim?","button","I haven't brought one yet¦46¦You were injured as a result of the accident, but you haven't instructed a solicitor to pursue the claim for you just yet.|I have brought one and it is ongoing¦36¦You were injured as a result of the accident and you have instructed a solicitor to act for you. Your claim is still ongoing|I have brought one and it has concluded¦36¦You were injured as a result of the accident and you instructed a solicitor to pursue the claim for you. Your claim has concluded."],

    ["Your Personal Injury Solicitors details (not HF)","title"],
    ["Do you know the name of the firm of solicitors that represents/represented you in respect to your personal injury?","button","Yes¦38|No¦46"],
    ["What is the name of the firm that represented you?","text","Type name here...¦40"],
    ["","",""],
    ["Do you know their reference?","button","Yes¦41|No¦42"],
    ["Reference number:","text","Type here...¦42"],
    ["Do you know the name of the solicitor","button","Yes¦43|No¦44"],
    ["What is the name of the solicitor?","text","Type name here...¦44"],
    ["Do you have their email?","button","Yes¦45|No¦46"],
    ["What is your solicitors email address?","email","Type here...¦46"],
    
    ["Other Claims","title"],
    ["Do you have a policy excess to claim?","button-with-video","Yes¦48|No¦49¬https://player.vimeo.com/video/651890635?h=46f982b520&autoplay=1¦Your policy excess is the amount of money you are expected to contribute towards the cost of any claim you make following an accident. When your car is damaged in an accident, the insurance company will expect you to pay towards the overall cost of the repairs. If the damage to your car costs £1,250 and your excess is £250, then you pay £250 towards the repairs and the insurance company pays the remaining £1,000. Alternatively, if your vehicle was considered to be uneconomical to repair your insurer may have deducted your policy excess from the Pre-Accident value of your vehicle. Therefore, if you had to pay an excess or, if it was deducted from the Pre-Accident value of your vehicle and you wish us to include it in the claim, then please let us know now."],
    ["Please enter the amount you wish to claim for policy excess.","number-text","Only in £...¦49"],
    ["Do you have a loss of earnings to claim?","button-with-video","Yes¦50|No¦51¬https://player.vimeo.com/video/651890780?h=f68eeac7e7&autoplay=1¦You may have suffered additional losses following this accident, such as a loss of earnings. If you believe you have lost earnings as a result of the accident then please include them now. You will be asked to provide evidence, such as payslips, in support of this if you do want to include it."],
    
    ["Please enter the amount you wish to claim for your loss of earnings.","number-text","Only in £...¦51"],
    ["Do you have any miscellaneous expenses to claim?","button-with-video","Yes¦52|No¦55¬https://player.vimeo.com/video/651890864?h=86a9376b63&autoplay=1¦In addition to losses, such as loss of earnings, or other costs you have had to pay out, such as a policy excess, there may be other losses that you wish to include. These are often referred to as “miscellaneous expenses” and covers costs such as travel expenses if you had to pay for travel to and from a repair garage, for example. If you believe you have additional expenses to include, then please include them now. You will be asked to provide evidence, such as receipts, in support of this if you do want to include them."],
    ["","expense-types",""],
    ["","",""],
    ["","",""],

    ["Payment Details","title"],
    ["If you have claimed for any losses, and we recover the money, we will need to send this money to you.","message","57"],
    ["We can either send you a cheque or transfer the money directly into your bank account via BACS. BACS is a much quicker and more secure way of sending the money to you. Which would you prefer?","button","BACS¦58|Cheque¦66"],
    //We can either send you a cheque or transfer the money directly into your bank account via BACS. BACS is a much quicker and more secure way of sending the money to you. Which would you prefer?"
    ["Are you happy to provide us with your bank details?","button-with-video","Yes¦59|No¦65¬https://player.vimeo.com/video/651891097?h=dc6242e57b&autoplay=1¦In the event we are able to recover your uninsured losses then our preference would be to send you your money via BACS, which is a quicker and more secure way of returning your monies. If you are happy to receive payment via BACS please select yes to – I would prefer payment by BACS, then provide the relevant details. In the event you do change your bank account before the case is settled it is imperative that you contact us to provide the new details. For security and audit reasons we will on occasion contact you by telephone to verify your BACS details."],
    ["Name on account","text","Type here...¦60"],
    ["Bank Name","text","Type here...¦61"],
    ["Account Number","number-text","Type here...¦62"],
    ["Sort Code","sort-code","Type here...¦63"],
    ["If you do change your bank account, or any of your contact details, before the case is settled it is important that you contact us to provide the new details. For security and audit reasons we will on occasion contact you by telephone to verify your BACS details. Please ensure that you double check that the details you have provided for your banking are correct before proceeding any further.","message","64"],
    ["Please ensure that you double check that the details you have provided for your banking are correct before proceeding any further.","message","67"],
    ["In the event that we recover any of your losses, we will contact you to obtain your bank details from you. Alternatively, please contact your case handler to provide these separately. In the event that you change any of your contact details before the case is settled, please provide us with your new details","message","67"],
    ["We will use the address that we hold on file for you to send the cheque to in the event that we recover any of your losses. If you change your address, or any of your contact details, before the case is settled it is imperative that you contact us to provide the new details.","message","67"],

    ["Confirmation","title"],
    ["We're almost there now. By clicking on 'I confirm that I agree' you are agreeing that the information provided is correct and that Horwich Farrelly are authorised to issue proceedings on your behalf. If you want to amend anything, please do so now, before clicking 'I confirm that I agree'.","checkbox-with-video","I confirm that I agree¦69¬https://player.vimeo.com/video/651890948?h=77b3ab1f3f&autoplay=1¦Next you will be asked to tick a box confirming that you agree that the information provided is correct and that Horwich Farrelly are authorised to issue proceedings on your behalf. The information you provide will be put into a document that contains a statement of truth. We need to certify that you believe the facts in this document are true with what is called a Statement of Truth. We are obliged to inform you that proceedings may be brought for contempt of court if you do not honestly believe that the facts stated in the Particulars of Claim are true. You have the opportunity to amend or add to any of these details now. If you want to change anything, or add anything, please do so now. If you do not wish to change anything, then please tick the box before clicking “Submit”. By doing this, you are then confirming that all of the information provided is correct and Horwich Farrelly are authorised to issue proceedings on your behalf."],
    ["Would you like a copy of the answers sent to your email?","button","Yes¦70|No¦71"],
    ["Enter email address","email","Type here...¦71"],
  ];

  questionListExpenseType = [
    ["Please choose the type(s) of miscellaneous expense you wish to claim for from the list below","dropdown","Petrol¦55|Travel Receipts¦55|Telephone Calls¦55|Child Car Seats¦55|"+this.otherValueAnswer+"¦55"],
    ["Other:","text","Type here...¦55"],
    ["Please enter the amount you wish to claim for your miscellaneous expense.","number-text","Only in £...¦55"]
  ];

  answersList = new Array(this.questionList.length).fill("");
  displayedQuestions = new Set([0]);
  jsonObj: string = "";
  jsonResult = "";

  regEmail = new RegExp("^^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+[.]+[a-zA-]{2,5}$");
  regNumber = new RegExp("[0-9]");
  regSortCode = new RegExp("[0-9-]");

  constructor(private modalService: ModalService,
    private userValidationService: UserValidationService, 
    private router: Router, 
    private httpService: HttpService, 
    private checkpointsService: CheckpointsService, 
    private appRef: ApplicationRef,
    private recaptchaV3Service: ReCaptchaV3Service) {

    this.personalDetailsAnswersList[0]=this.userValidationService.validationAnswers[0];
    this.personalDetailsAnswersList[1]=this.userValidationService.validationAnswers[1];
    this.personalDetailsAnswersList[2]=this.userValidationService.validationAnswers[2];
    
  }


  ngOnInit(): void {
    document.body.scrollTop;
    this.userValidationService.captchaResponse = "";
    this.httpService.isNotWelcomePage = true;
  }

  ngAfterViewInit(): void{
    setTimeout(()=>{
      this.displayNewQuestion(-1,0);
    }, 500);
  }

  displayNewQuestion(currentQuestion: number, newQuestion: number){
    let progressBarId = this.getId(newQuestion);
    this.checkpointsService.setTitleId(progressBarId);
    this.appRef.tick();
    this.removeSubsequentQuestions(currentQuestion, newQuestion);
    this.displayedQuestions.add(newQuestion);
    if(newQuestion<this.questionList.length){
      while (this.questionList[newQuestion][1] == "title"){
        newQuestion++;
        this.displayedQuestions.add(newQuestion);
      }
      if(this.questionList[newQuestion][1] == "message"){
        let questionAfterMessage = parseInt(this.questionList[newQuestion][2]);
        this.displayNewQuestion(newQuestion,questionAfterMessage);
      }
    }
  }

  removeSubsequentQuestions(currentQuestion: number, nextQuestion: number){
    if(this.questionList[currentQuestion] != null){

      if(this.questionList[currentQuestion][1] == "button" || this.questionList[currentQuestion][1] == "email" || this.questionList[currentQuestion][1] == "message" ||  this.questionList[currentQuestion][1] == "title" ){
        for(const x of this.displayedQuestions){
          if(x > currentQuestion && x < nextQuestion){
            this.displayedQuestions.delete(x);
            this.removeCurrentAnswer(x);
          }
        }
      }
      else if( this.questionList[currentQuestion][1] == "dropdown"){
        for(const x of this.displayedQuestions){
          if(x > currentQuestion && (x < nextQuestion || nextQuestion == undefined) && !this.answersList[currentQuestion].includes(this.otherValueAnswer) ){
            this.displayedQuestions.delete(x);
            this.removeCurrentAnswer(x);
          }
        }
      }
      
      else if(this.questionList[currentQuestion][1]=="button-with-video"){

        var yesAnswer = parseInt(this.questionList[currentQuestion][2].split("|")[0].split('¦')[1]);

        if((this.questionList[yesAnswer][1] == "text" || this.questionList[yesAnswer][1] == "text-nonrequired")
        && (this.questionList[yesAnswer + 1][1] == "text" || this.questionList[yesAnswer + 1][1] == "text-nonrequired")
        && (this.questionList[yesAnswer + 2][1] == "text" || this.questionList[yesAnswer + 2][1] == "text-nonrequired")
        && (this.questionList[yesAnswer + 3][1] == "text" || this.questionList[yesAnswer + 3][1] == "text-nonrequired")){
          for(const x of this.displayedQuestions){
            if(x >= yesAnswer && x <= yesAnswer + 3){
              this.displayedQuestions.delete(x);
              this.removeCurrentAnswer(x);
            }
          }
        }
        else {
          for(const x of this.displayedQuestions){
            if(x > currentQuestion && x < nextQuestion){
              this.displayedQuestions.delete(x);
              this.removeCurrentAnswer(x);
            }
          }
        }
      }
    }
  }

  removeSubsequentQuestionsAndUpdateValue(currentQuestion: number, nextQuestion: number, ans: string){
    this.answersList[currentQuestion] = ans;
    this.removeSubsequentQuestions(currentQuestion, nextQuestion);
  }

  removeCurrentAnswer(currentQuestion: number){

    this.answersList[currentQuestion] = "";
    if(this.questionList[currentQuestion][1]=="button"||this.questionList[currentQuestion][1]=="button-with-video"){
      let possibleButtonAnswers = this.questionList[currentQuestion][2].split('|');
      for(var j = 0; j< possibleButtonAnswers.length;j++){
        let answer = possibleButtonAnswers[j].split('¦')[0];
        let buttonElement = document.getElementById('question-'+currentQuestion+'-answer-'+answer);
        buttonElement?.classList.remove('selected-button');
        buttonElement?.classList.add('watch-video-button');
      }
    }
  }

  submitQuestionForm(){
    //this.listAnswersInConsole();
    this.isSubmitted = true;
    let isValid = this.validateAnswers();
    if(isValid){
      this.recaptchaV3Service.execute('').subscribe(
        token => {
          console.log(`Recaptcha v3 token received`);
          this.userValidationService.captchaResponse = token;
          this.mergeArray();
          this.updateCase();
        },
        error => {
          console.log(`Case update failed. Recaptcha v3 error:`, error);
        }
      );
      
    }
    else{
      this.modalService.open('validation-failure-modal');
      this.isSubmitted = false;
    }
  }

  validateAnswers(){
    for(const x of this.displayedQuestions){
      if(x < this.questionList.length && this.questionList[x][1] == "number-text"){
        if(!this.validateNumberAnswer(this.answersList[x])){
          return false;
        }
      }
      else if(x < this.questionList.length && this.questionList[x][1] == "sort-code"){
        if(!this.validateSortCodeAnswer(this.answersList[x])){
          return false;
        }
      }
      else if(x < this.questionList.length && this.questionList[x][1] == "email"){
        if(!this.validateEmailAnswer(this.answersList[x])){
          return false;
        }
      }
      else if(x < this.questionList.length && this.questionList[x][1] == "date"){
        if(!this.validateDateAnswer(this.answersList[x])){
          return false;
        }
        else{
          this.answersList[x] = this.changeDateFormat(this.answersList[x]);
        }
      }
      else if(x < this.questionList.length && this.questionList[x][1] == "expense-types"){
        if(!this.validateExpenseTypeAnswer(this.answersList[x])){
          return false;
        }
      }
      if(this.answersList[x] == "" && this.isInputField(x)){
        return false;
      }
    }
    return true;
  }

  isInputField(index: number){
    if(this.questionList[index][1] == "date" || this.questionList[index][1] == "big-text" || this.questionList[index][1] == "number-text" || this.questionList[index][1] == "text" || this.questionList[index][1] == "text-nonrequired" || this.questionList[index][1] == "email"){
      return true;
    }
    return false;
  }

  validateExpenseTypeAnswer(expenseTypes: []): boolean{
    var availableExpenseTypes = this.questionListExpenseType[0][2].split("|").map(x => x.substring(0, x.indexOf("¦")));

    for(const x of expenseTypes){
      if(availableExpenseTypes.includes(x[0])){
        if(x[0] == this.otherValueAnswer && x[1] == ""){
          return false;
        }
        if(!this.validateNumberAnswer(x[2]) || x[2] == ""){
          return false;
        }
      }
      else
        return false;
    }
    return true;
  }

  listAnswersInConsole(){
    //this.modalService.open('submit-modal');
    for(const item of this.answersList){
      console.log(item);
    }
  }

  mergeArrayForSendEmail(vfOutput: string){
    
    this.jsonObj = '{ "QuestionForm": {';
    this.jsonObj = this.jsonObj + '"' + "CaptchaResponse" + '":"' + this.userValidationService.captchaResponse + '",';
    this.jsonObj = this.jsonObj + '"' + "ValidatedEnvironment" + '":"' + environment["ValidatedEnvironment"] + '",';
    for(var i = 0; i < this.personalDetailsQuestionsList.length; i++){
        this.jsonObj = this.jsonObj + JSON.stringify(this.personalDetailsQuestionsList[i]) + ':' + JSON.stringify(this.personalDetailsAnswersList[i]) + ',';
    }

    for(var i = 0; i < this.questionList.length; i++){
      if(this.questionList[i][1] == "expense-types"){
        this.jsonObj = this.jsonObj + "ExpenseTypes:[";
        for(var j = 0; j < this.answersList[i].length; j++){
          this.jsonObj = this.jsonObj + "{";
          for(var z = 0; z < this.questionListExpenseType.length; z++){
            if(this.answersList[i][j][z] !== undefined || this.answersList[i][j][z] != ""){
                this.jsonObj = this.jsonObj + JSON.stringify(this.questionListExpenseType[z][0]) + ':' + JSON.stringify(this.answersList[i][j][z]);
                if(i !== this.questionListExpenseType.length-1){
                  this.jsonObj = this.jsonObj + ',';
                }
            }
          }
          this.jsonObj = this.jsonObj + "}";
          if(i !== this.answersList[i].length-1){
            this.jsonObj = this.jsonObj + ',';
          }
        }
        this.jsonObj = this.jsonObj + "],";
      }
      else{
        this.jsonObj = this.jsonObj + JSON.stringify(this.questionList[i][0]) + ":" + JSON.stringify(this.answersList[i]);
        if(i !== this.questionList.length-1){
          this.jsonObj = this.jsonObj + ',';
        }
      }
      
    }
    
    this.jsonObj = this.jsonObj + '}';
    if(vfOutput != "")
      this.jsonObj = this.jsonObj + ', "VFOutput" : ' + vfOutput;
    this.jsonObj = this.jsonObj + '}';
  }

  mergeArray(){
    
    this.jsonObj = '{';
    this.jsonObj = this.jsonObj + '"' + "CaptchaResponse" + '":"' + this.userValidationService.captchaResponse + '",';
    this.jsonObj = this.jsonObj + '"' + "ValidatedEnvironment" + '":"' + environment["ValidatedEnvironment"] + '",';
    for(var i = 0; i < this.personalDetailsQuestionsList.length; i++){
        this.jsonObj = this.jsonObj + JSON.stringify(this.personalDetailsQuestionsList[i]) + ':' + JSON.stringify(this.personalDetailsAnswersList[i]) + ',';
    }

    for(var i = 0; i < this.questionList.length; i++){
      if(this.questionList[i][1] == "expense-types"){
        this.jsonObj = this.jsonObj + "ExpenseTypes:[";
        for(var j = 0; j < this.answersList[i].length; j++){
          this.jsonObj = this.jsonObj + "{";
          for(var z = 0; z < this.questionListExpenseType.length; z++){
            if(this.answersList[i][j][z] !== undefined || this.answersList[i][j][z] != ""){
                this.jsonObj = this.jsonObj + JSON.stringify(this.questionListExpenseType[z][0]) + ':' + JSON.stringify(this.answersList[i][j][z]);
                if(i !== this.questionListExpenseType.length-1){
                  this.jsonObj = this.jsonObj + ',';
                }
            }
          }
          this.jsonObj = this.jsonObj + "}";
          if(i !== this.answersList[i].length-1){
            this.jsonObj = this.jsonObj + ',';
          }
        }
        this.jsonObj = this.jsonObj + "],";
      }
      else{
        this.jsonObj = this.jsonObj + JSON.stringify(this.questionList[i][0]) + ":" + JSON.stringify(this.answersList[i]);
        if(i !== this.questionList.length-1){
          this.jsonObj = this.jsonObj + ',';
        }
      }
      
    }
    
    this.jsonObj = this.jsonObj + '}';
  }

  sendMail(){
    setTimeout(()=> this.httpService.sendMail(this.jsonObj).subscribe(response => {
      if(response.status == 200){
        console.log(response);
        this.jsonResult = JSON.stringify(response);

        if(this.jsonResult.includes("User invalid")){
          this.validUser = false;
          this.modalService.open('failed-to-authenticate-modal');        
        }
        else{
          this.validUser = true;
          this.router.navigateByUrl('submission-complete');
        }
      }
      else{
        this.modalService.open('submit-failure-modal');
      }
      this.isSubmitted = false;
    },
    err => {
      console.log(err);
      this.modalService.open('submit-failure-modal');
      this.isSubmitted = false;
    }))
  }

  SubmitSendEmail(vfIntegrationOutput: string){
    this.recaptchaV3Service.execute('').subscribe(
      token => {
        console.log(`Recaptcha v3 token received`);
        this.userValidationService.captchaResponse = token;
        this.mergeArrayForSendEmail(vfIntegrationOutput);
        this.sendMail();
      },
      error => {
        this.modalService.open('submit-failure-modal');
        this.isSubmitted = false;
        console.log(`Mail send failed. Recaptcha v3 error:`, error);
      }
    );
  }

  updateCase(){
    var VFIntegrationOutput: string = "";
    setTimeout(()=> this.httpService.updateCase(this.jsonObj).subscribe(response => {
      if(response.status == 200){
        console.log(response);
        console.log('Case update succeeded');
      }
      VFIntegrationOutput = response.body ? response.body.toString() : "";
      console.log('Case update error: ' + '{"outputMessage": "Case update error: ' + VFIntegrationOutput + '"}');
      this.SubmitSendEmail(VFIntegrationOutput);
    },
    err => {
      console.log('Case update error: ' + err);
      VFIntegrationOutput = '{"outputMessage": "Case update error: ' + err + '"}';
      this.SubmitSendEmail(VFIntegrationOutput);
    }))
    
  }

 closeFailureModal(){
  this.modalService.close('submit-failure-modal');
 }

  closeValidationFailureModal(){
  this.modalService.close('validation-failure-modal');
 }


 closeAuthenticationFailModal(){
  this.modalService.close('failed-to-authenticate-modal');
 }


  answerQuestion(questionNumber:number,nextQuestionNumber:number,answer:string){

    if(answer != this.answersList[questionNumber] || this.questionList[questionNumber][1] == "dropdown-sequence"){
      this.answersList[questionNumber] = answer;

      //Stops text/date fields deleting/updating subsequent questions unless they were originally empty.
      if(this.questionList[questionNumber][1]=="text"|| this.questionList[questionNumber][1]=="text-nonrequired"|| this.questionList[questionNumber][1]=="big-text"|| this.questionList[questionNumber][1]=="date"){
        this.displayNewQuestion(questionNumber,nextQuestionNumber);
      }

      if(this.questionList[questionNumber][1] == "email" && this.validateEmailAnswer(answer)){
        this.displayNewQuestion(questionNumber,nextQuestionNumber);
      }

      if(this.questionList[questionNumber][1] == "number-text" && this.validateNumberAnswer(answer)){
        this.displayNewQuestion(questionNumber,nextQuestionNumber);
      }

      if(this.questionList[questionNumber][1] == "sort-code" && this.validateSortCodeAnswer(answer)){
        this.displayNewQuestion(questionNumber,nextQuestionNumber);
      }
      if(this.questionList[questionNumber][1] == "checkbox" || this.questionList[questionNumber][1] == "checkbox-with-video" || this.questionList[questionNumber][1] == "expense-types"){
          this.displayNewQuestion(questionNumber,nextQuestionNumber);
      }

      

      if(this.questionList[questionNumber][1]=="button"||this.questionList[questionNumber][1]=="button-with-video"){
        let possibleButtonAnswers = this.questionList[questionNumber][2].split('|');
          for(var i = 0; i< possibleButtonAnswers.length;i++){
            let answer = possibleButtonAnswers[i].split('¦')[0];
            let buttonElement = document.getElementById('question-'+questionNumber+'-answer-'+answer);
            buttonElement?.classList.remove('selected-button');
            buttonElement?.classList.add('watch-video-button');
          }
        let answeredButtonElement = document.getElementById('question-'+questionNumber+'-answer-'+answer);
        answeredButtonElement?.classList.add('selected-button');
        answeredButtonElement?.classList.remove('watch-video-button');
        this.displayNewQuestion(questionNumber,nextQuestionNumber);
      }
    }
  }

  skipOrCloseVideo(questionNumber:number){
    let isLatestQuestion = true;
    for(const x of this.displayedQuestions){
      if(x > questionNumber){
        isLatestQuestion = false;
      }
    }
    if(isLatestQuestion){
      let nextQuestion = this.questionList[questionNumber][2].split('¦')[1];
      this.displayNewQuestion(questionNumber, parseInt(nextQuestion));
    }
  }

  allQuestionsAnswered(){
    let latestDisplayedQuestion = 0;
    let secondlatestDisplayQuestion = 0;
    for(const x of this.displayedQuestions){
      if(x > latestDisplayedQuestion){
        secondlatestDisplayQuestion = latestDisplayedQuestion;
        latestDisplayedQuestion = x;
      }
      else if(x > secondlatestDisplayQuestion){
        secondlatestDisplayQuestion = x;
      }
    }

    if(latestDisplayedQuestion != null && latestDisplayedQuestion !== 0){
      if(this.questionList[secondlatestDisplayQuestion][1] == "email"){
        return this.validateEmailAnswer(this.answersList[this.answersList.length - 1]);
      }
      else if(this.questionList[secondlatestDisplayQuestion][1] == "button"){
        return latestDisplayedQuestion >= this.questionList.length;
      }
    }

    return latestDisplayedQuestion >= this.questionList.length;
  }

  validateEmailAnswer(email: string): boolean{
    return this.regEmail.test(email);
  }

  changeDateFormat(stringDate: string): string{
    try{
      var dateParts = stringDate.split('-');
      var day = dateParts[2];
      var month = dateParts[1];
      var year = dateParts[0];
      return day + '/' + month + '/' + year;
    }
    catch{
      return stringDate;
    }
  }

  validateDateAnswer(stringDate: string): boolean{
    var isValid = false;
    try{
      var dateParts = stringDate.split('-');
      var day = parseInt(dateParts[2]);
      var month = parseInt(dateParts[1])-1;
      var year = parseInt(dateParts[0]);
      var date = new Date();
      date.setDate(day);
      date.setMonth(month);
      date.setFullYear(year);
      var currentTime = new Date();
      if(date <= currentTime){
        isValid = true;
      }
    }
    finally{
      return isValid;
    }
    
  }

  validateNumberAnswer(numberAnswer: string): boolean{
    return this.regNumber.test(numberAnswer);
  }

  validateSortCodeAnswer(sortCodeAnswer: string): boolean{
    return this.regSortCode.test(sortCodeAnswer);
  }

  getId(questionNumber: number){
    let titleId = 1;
    if(questionNumber >= this.questionList.length){
      return 9;
    }
    for(var i = 0; i <= questionNumber;i++){
      if(this.questionList[i][1] == "title"){
        if(titleId < 8)
          titleId++;
      }
    }
    return titleId;
  }
}
