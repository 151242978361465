<div style="padding-top: 250px">
<div class="central" style="padding-bottom: 100px;">

    <div class="container">
        <div class="body-container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="title">
                PERSONAL DETAILS
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <span class="" aria-label="PERSONAL DETAILS">These are the details we hold on our file for you. Please would you kindly enter your email address and / or telephone number below, if you have one. This is not mandatory, but will ensure that we have up-to-date details for you on our file</span>
            </div>
        </div>
        <br/>
        <div class="row col-sm-12">
            <label for="your-name">{{personalDetailsQuestionsList[0]}}:</label>
        </div>
        <div class="row">
            <div class="col-sm-12">
              <input type="text" readonly id="your-name" value={{personalDetailsAnswersList[0]}}>
            </div>
        </div>
        <div class="row col-sm-12">
            <label for="your-name">{{personalDetailsQuestionsList[1]}}:</label>
        </div>
        <div class="row">
            <div class="col-sm-12">
              <input type="text" readonly  id="reference-number" value={{personalDetailsAnswersList[1]}}>
            </div>
        </div>
        <div class="row col-sm-12">
            <label for="your-name">{{personalDetailsQuestionsList[2]}}:</label>
        </div>
        <div class="row">
            <div class="col-sm-12">
              <input type="text" readonly id="register-number" value={{personalDetailsAnswersList[2]}}>
            </div>
        </div>
        <div class="row col-sm-12">
            <label for="your-name" >{{personalDetailsQuestionsList[3]}}:</label>
        </div>
        <div class="row">
            <div class="col-sm-12">
              <input type="text" id="email" maxlength="250" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" ngModel #emailref="ngModel" [(ngModel)]="personalDetailsAnswersList[3]" placeholder="Type here...">
              <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
                <div class="invalid" [hidden]="!emailref.errors?.pattern">
                    Invalid Email
                </div> 
            </div>  
            </div>
        </div>
        <div class="row col-sm-12">
            <label for="your-name">{{personalDetailsQuestionsList[4]}}:</label>
        </div>
        <div class=" row">
            <div class="col-sm-12">
              <input type="text" id="telephone-number" placeholder="Type here..." [(ngModel)]="personalDetailsAnswersList[4]" >
            </div>
        </div> 
        <br/>
        <div *ngFor="let item of questionList; index as i;">
            <div style="text-align: left;" *ngIf="displayedQuestions.has(i)">
                
                <div class="col-12" *ngIf='displayedQuestions.has(i) && questionList[i][1]!="title" && questionList[i][1]!="text" && questionList[i][1]!="text-nonrequired" && questionList[i][1]!="big-text" && questionList[i][1]!="number-text" && questionList[i][1]!="email" && questionList[i][1]!="date" && questionList[i][1]!="button" && questionList[i][1]!="dropdown" '>
                   <p class="" attr.aria-label="{{questionList[i][0]}}">{{questionList[i][0]}}</p>
                </div>
                <div class="col-12" style="text-align: center; padding-top: 20px;" *ngIf='displayedQuestions.has(i) && (questionList[i][1]=="date" || questionList[i][1]=="big-text")'>
                    <p class="" attr.aria-label="{{questionList[i][0]}}">{{questionList[i][0]}}</p>
                 </div>
                <div class="col-12"  style=" padding-top: 20px;" *ngIf='displayedQuestions.has(i) && (questionList[i][1]=="text" || questionList[i][1]=="text-nonrequired" || questionList[i][1]=="number-text" || questionList[i][1]=="email"  || questionList[i][1]=="button")'>
                   <p class="" attr.aria-label="{{questionList[i][0]}}">{{questionList[i][0]}}</p>
                </div>
                <div class="col-12" *ngIf='questionList[i][1]=="text" && displayedQuestions.has(i)'>
                    <app-text-field  (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [options]="questionList[i][2]" ></app-text-field>
                </div>
                <div class="col-12" *ngIf='questionList[i][1]=="text-nonrequired" && displayedQuestions.has(i)'>
                    <app-text-field-nonrequired  (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [options]="questionList[i][2]" ></app-text-field-nonrequired>
                </div>
                <div class="col-12" style="text-align: center;" *ngIf='questionList[i][1]=="big-text" && displayedQuestions.has(i)'>
                    <app-big-text-field  (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [options]="questionList[i][2]" ></app-big-text-field>
                </div>
                <div class="col-12" *ngIf='questionList[i][1]=="number-text" && displayedQuestions.has(i)'>
                    <app-number-text-field  (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [options]="questionList[i][2]" ></app-number-text-field>
                </div>
                <div class="col-12" *ngIf='questionList[i][1]=="sort-code" && displayedQuestions.has(i)'>
                    <app-sort-code-field  (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [options]="questionList[i][2]" ></app-sort-code-field>
                </div>
                <div class="col-12" *ngIf='questionList[i][1]=="email" && displayedQuestions.has(i)'>
                    <app-email-field (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [options]="questionList[i][2]" ></app-email-field>
                </div>

                <div class="col-12" style="text-align: center;" *ngIf='questionList[i][1]=="date" && displayedQuestions.has(i)' >
                    <app-date-selector (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [options]="questionList[i][2]"></app-date-selector>
                </div>

                <div class="col-12" *ngIf='questionList[i][1]=="button" && displayedQuestions.has(i)'>
                    <app-button-selection (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [currentQuestion]="i" [options]="questionList[i][2]"></app-button-selection> 
                </div>

                <div class="col-12" *ngIf='questionList[i][1]=="button-with-video" && displayedQuestions.has(i)'>
                    <app-button-selection (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [currentQuestion]="i" [options]="questionList[i][2].split('¬')[0]"></app-button-selection>     
                    <br/>
                    <app-video-buttons [noThankYouRequired]="false" [writtenExplanation]="questionList[i][2].split('¬')[1].split('¦')[1]" [url]="questionList[i][2].split('¬')[1].split('¦')[0]" [id]="'modal'+i"></app-video-buttons>
                </div>

                <div class="col-12" *ngIf='questionList[i][1]=="checkbox-with-video" && displayedQuestions.has(i)'>
                    <app-video-buttons [noThankYouRequired]="false" [writtenExplanation]="questionList[i][2].split('¬')[1].split('¦')[1]" [url]="questionList[i][2].split('¬')[1].split('¦')[0]" [id]="'modal'+i"></app-video-buttons>
                    <br/>
                    <app-checkbox-selector (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [options]="questionList[i][2].split('¬')[0]"></app-checkbox-selector>
                </div>

                <div class="col-12" *ngIf='questionList[i][1]=="video" && displayedQuestions.has(i)'>
                    <app-video-buttons [downArrow]="true" [writtenExplanation]="questionList[i][2].split('¦')[2]" (answered)="skipOrCloseVideo(i);" [url]="questionList[i][2].split('¦')[0]" [id]="'modal'+i"></app-video-buttons>
                </div>

                <div class="col-12" *ngIf='questionList[i][1]=="expense-types" && displayedQuestions.has(i)'>
                    <app-dropdown-sequence (answered)="answerQuestion(i,$event.nextQ,$event.ans)"  [questionList]="questionListExpenseType" [otherValueAnswer]="otherValueAnswer"></app-dropdown-sequence>
                </div>

                <div class="col-12" *ngIf='questionList[i][1]=="checkbox" && displayedQuestions.has(i)'>
                    <app-checkbox-selector (answered)="answerQuestion(i,$event.nextQ,$event.ans)" [options]="questionList[i][2]"></app-checkbox-selector>
                </div>
    
                <div class="col-12" *ngIf='questionList[i][1]=="title" && displayedQuestions.has(i)'>
                    <div class="input-label title" attr.aria-label="{{questionList[i][0]}}">{{questionList[i][0].toUpperCase()}}</div>
                </div>
                
            </div>
            
        </div>
    </div>
    <div class="navigation-container">
        <div *ngIf="allQuestionsAnswered()" class="row justify-content-center">
            <div class="col-12" style="text-align: right; padding-top: 50px;">
                <button class="btn proceed-button" (click)="submitQuestionForm()" aria-label="Next Page" tabindex="0" [disabled]="isSubmitted">
                    <span *ngIf="isSubmitted" class="spinner-border spinner-border-sm" style="display: inline-block" role="status" aria-hidden="true"></span>
                    Submit
                </button>
                <jw-modal id="submit-failure-modal" url="text">
                    <div>
                        <label style="margin-bottom: 50px;" class="input-label" aria-label="Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused.">Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" aria-label="Close" (click)="closeFailureModal()" tabindex="0">Close</button>
                    </div>
                </jw-modal>
                <jw-modal id="validation-failure-modal" url="text">
                    <div>
                        <label style="margin-bottom: 50px;" class="input-label" aria-label="There has been an issue submitting your details. Please check all the text boxes have been filled in correctly." >There has been an issue submitting your details. Please check all the text boxes have been filled in correctly.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" aria-label="Close" (click)="closeValidationFailureModal()" tabindex="0">Close</button>
                    </div>
                </jw-modal>
                <jw-modal id="failed-to-authenticate-modal" url="text">
                    <div>
                        <label style="margin-bottom: 250px;" aria-label="Unfortunately the details you have provided does not match the information that we hold on file for you. Please click on 'try again' and enter the correct details. If you need to speak to us, please contact the case handler. Their contact details are provided at the top of the letter we sent to you." class="input-label">Unfortunately the details you have provided does not match the information that we hold on file for you. Please click on "try again" and enter the correct details. If you need to speak to us, please contact the case handler. Their contact details are provided at the top of the letter we sent to you.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" (click)="closeAuthenticationFailModal();" tabindex="0">Try again</button>
                    </div>
                </jw-modal>
            </div>
            
        </div> 
    </div>
    </div>
    
     
</div>
</div>