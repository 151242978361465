import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserValidationService} from '../services/user-validation.service';

@Injectable({
  providedIn: 'root'
})
export class UserValidationGuard implements CanActivate {
  constructor(private userValidationService: UserValidationService, private router:Router) {
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.userValidationService.IsUserValidated()){
        return true;
      }
      else {
        this.router.navigateByUrl('/welcome');
        return false;
      }
  }
  
}
