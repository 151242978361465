<div style="padding-top: 250px">
<div class="central">

    <div class="container" style="padding-left: 20px; padding-right: 20px; position:relative;">
        <div class="body-container">
            <br/>
            <div class="title" style="padding:10px">
                THANK YOU!
            </div>
            <label class="input-label-modal" aria-label=" Thank you so much. All of these details have now been passed on to the team at Horwich Farrelly. There's no need to contact us, we'll let you know if we need anything further from you."> Thank you so much. All of these details have now been passed on to the team at Horwich Farrelly. There's no need to contact us, we'll let you know if we need anything further from you.</label>
            <br/>
            <br/>
            <div class="title" style="padding:10px">
                FREQUENTLY ASKED QUESTIONS
            </div>
            <label class="input-label-modal" aria-label="In case you have any further questions, we have included a few more videos and written explanations. Please feel free to view them, or if you're ready now, please press finish.">In case you have any further questions, we have included a few more videos and written explanations. Please feel free to view them, or if you're ready now, please press finish.</label>
           
            <div style="display: inline-block; width: 100%; height: 50px;">
                <span class="input-label" style="display: inline-block;width: 70%;" aria-label="FAQs-involvement">What is my Involvement?</span>
                <app-video-buttons id="your-details-modal-video" style="display: inline-block; text-align: right; width: 30%;" [displayText]="false" [noThankYouRequired]="false" writtenExplanation="Initially all we ask you to do is provide us with details of any uninsured losses that you have incurred and to also check the accident information we have on the covering letter to ensure all the information we have about the accident is correct. The sooner you provide this information the sooner we can progress your claim. If the third party insurers deny liability for the accident or fail to acknowledge our correspondence then we would issue proceedings in your name and at this point we would contact you further." url="https://player.vimeo.com/video/501185362?h=d59cc1eba1&autoplay=1" id="your-details-modal-video1"></app-video-buttons>    
            </div>
            <div style="display: inline-block; width: 100%; height: 50px;">
                <span class="input-label" style="display: inline-block;width: 70%;" aria-label="FAQs-premiums">Can I recover my premiums?</span>
                <app-video-buttons id="your-details-modal-video" style="display: inline-block; text-align: right; width: 30%;" [displayText]="false" [noThankYouRequired]="false" writtenExplanation="Increased insurance premiums are not something we would look to recover from the other parties insurers as providing we recover all the monies from the other insurer then your no claims bonus will be reinstated, subject to you not having been involved in any other accidents, and any additional insurance premiums paid will ordinarily be refunded by your motor insurers at conclusion of the claim (note the refund of any insurance premium will be calculated by your insurers and there is no guarantee that this would be the exact difference between your original and current premiums).  Should you have any further queries in this regard then you should contact your motor insurers to discuss the same as this is not something that we deal with or have control of." url="https://player.vimeo.com/video/501185621?h=57f18eb9d5&autoplay=1" id="your-details-modal-video2"></app-video-buttons>    
            </div>
            <div style="display: inline-block; width: 100%; height: 50px;">
                <span class="input-label" style="display: inline-block;width: 70%;" aria-label="FAQs-next">What happens next?</span>
                <app-video-buttons id="your-details-modal-video" style="display: inline-block; text-align: right; width: 30%;" [displayText]="false" [noThankYouRequired]="false" writtenExplanation="Once you have returned/submitted your uninsured loss questionnaire we will present details of your claim to the other party and/or his/her motor insurers. If payment is not forthcoming or if liability is disputed then we would review our file with a view to issuing proceedings against the person we consider to be at fault for the accident. From issuing court proceedings to the matter going to trial usually takes approximately six months.  The third party could choose to settle the matter at any stage during this process. In the event we do make a successful recovery monies owed to your insurers are sent directly to them and any monies owing to you will be sent to you. As previously mentioned you will not be charged anything for our involvement nor will any monies be deducted from your losses. All our costs will covered by your motor insurers." url="https://player.vimeo.com/video/501185668?h=d9c8c1db33&autoplay=1" id="your-details-modal-video3"></app-video-buttons>           
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div class="row justify-content-center">
            <div class="col-12" style="text-align: center; width:100%; bottom:20px; position:absolute;">
                <a class="proceed-button title-font" href="https://www.h-f.co.uk/" aria-label="Finish" tabindex="0">Finish</a>
            </div>
        </div>
        <br/>
    </div>
    
     
</div>
</div>