import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-checkbox-selector',
  templateUrl: './checkbox-selector.component.html',
  styleUrls: ['./checkbox-selector.component.css']
})
export class CheckboxSelectorComponent implements OnInit {

  @Input() options!: string;
  nextQuestion!:number;
  answer!:string;

  @Output() answered = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.answer = this.options.split('¦')[0];
    let nextQuestionString = this.options.split('¦')[1];
    this.nextQuestion = parseInt(nextQuestionString); 
    
    setTimeout(() => {
      this.updateCheckboxComponent();
    }, 10);
  }

  answerQuestion(nextQ:number,ans:string){
    this.answered.emit({nextQ,ans});
  }

  //DO NOT DELETE. 
  //Empty method used as a work around for the "answer" value not appearing when the checkbox is loaded in the question flow as part of a larger question.
  updateCheckboxComponent(){
  }
}
