export const environment = {
  production: true,
  UserValidationLookupUrl:"https://hfdigital-apim.azure-api.net/heart-uil/UserValidation",
  UpdateHeartCaseUrl:"https://hfdigital-apim.azure-api.net/heart-uil/UpdateHeartCase",
  MailAssistantUrl:"https://hfdigital-apim.azure-api.net/heart-uil/SendEmail",
  captchaKey: "6LemAAceAAAAAEGKzq2Rjleke9AoRvFATZV7vCdI",
  ValidatedEnvironment: 7,
  IsOffline: false,
  errorMessage: "Unfortunately, this service is currently unavailable. Thank you very much for your patience and we look forward to seeing you soon."
};
