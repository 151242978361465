<div style="display: flex; flex-flow: column; height: 100%;">
    <app-top-bar style="flex: 0 1 auto;"></app-top-bar>
    <!-- <progress-bar></progress-bar> -->
    <div style="flex: 1 1 auto;">
        <router-outlet ></router-outlet>
        <jw-modal id="vf-down-modal" url="text">
            <div>
                <label style="margin-bottom: 50px;" attr.aria-label="{{this.errorMessage}}" class="input-label">{{this.errorMessage}}</label>
                <br/>
            </div>
        </jw-modal>
    </div>
    
    <div style="min-height: 50px;"></div>
    <app-footer style="flex: 0 1 auto;"></app-footer>
</div>

