<div style="padding-top: 250px;">
<div class="central">
    <div class="container">
        <div class="body-container">
        <div class="title">
            VALIDATION STAGE
        </div>
        <p style="padding-bottom: 10px;">Before we get started, we need to confirm a few details with you. Please would you answer the question below.</p>
                <div class="row ">
                   <div class="col-md-12 mb-3">
                        <label for="name" class="required-field" aria-label="Your name">Your full name (excluding your title)</label>
                        <input type="text" class="form-control" id="name" pattern="[a-zA-Z0-9-\x27 ]*" maxlength="250" #fullnameref="ngModel" ngModel [(ngModel)]="answersList[0]" placeholder="Type here...">
                        <div *ngIf="fullnameref.errors &&(fullnameref.touched || fullnameref.dirty)">
                            <div class="invalid" [hidden]="!fullnameref.errors?.pattern">
                                Invalid full name
                            </div> 
                        </div> 
                    </div>
                        <div class="col-md-12 mb-3">
                            <label for="referenceNumber" class="required-field" aria-label="Please insert your reference number. This is just the numbers from the reference at the top of the letter we have sent to you. Please don't include the letters. For 12345/12345-ABC please just use 12345/12345." >Please insert your reference number. This is just the numbers from the reference at the top of the letter we have sent to you. Please don't include the letters. For 12345/12345-ABC please just use 12345/12345.</label>
                            <input type="text" class="form-control" id="referenceNumber" pattern="[0-9/]*"   maxlength="250" #referencenumberref="ngModel" ngModel [(ngModel)]="answersList[1]" placeholder="Type here...">
                            <div *ngIf="referencenumberref.errors &&(referencenumberref.touched || referencenumberref.dirty)">
                                <div class="invalid" [hidden]="!referencenumberref.errors?.pattern">
                                    Invalid reference number
                                </div> 
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label for="registrationNumber" class="required-field" aria-label="Registration number of the vehicle you were driving at the time of the incident (please enter as one number with no spaces)">Registration number of the vehicle you were driving at the time of the incident (please enter as one number with no spaces)</label>
                            <input type="text" class="form-control" id="registrationNumber" pattern="[a-zA-Z0-9]*"   maxlength="250" #registrationnumberref="ngModel" ngModel [(ngModel)]="answersList[2]" placeholder="Type here...">
                            <div *ngIf="registrationnumberref.errors && (registrationnumberref.touched || registrationnumberref.dirty)">
                                <div class="invalid" [hidden]="!registrationnumberref.errors?.pattern">
                                    Invalid registration number
                                </div> 
                            </div>
                        </div>
                </div>
        </div>
    <div class="navigation-container">
        <div style="height: 100px;">
        <div *ngIf="hasAnswers() && !fullnameref.errors?.pattern && !referencenumberref.errors?.pattern && !registrationnumberref.errors?.pattern" class="row justify-content-center" >
            
            <div class="col-12" style="text-align: right;">
                <br/>
                <button class="btn proceed-button" id="submit-validation-button" (click)="submitForm()" aria-label="Next Page" tabindex="0" [disabled]="isSubmitted">
                    <span *ngIf="isSubmitted" class="spinner-border spinner-border-sm" style="display: inline-block" role="status" aria-hidden="true"></span>
                    Submit <i class="bi bi-chevron-right"></i>
                </button>
                <jw-modal id="failed-to-authenticate-modal" url="text">
                    <div>
                        <label style="margin-bottom: 50px;" aria-label="Unfortunately the details you have provided does not match the information that we hold on file for you. Please click on 'try again' and enter the correct details. If you need to speak to us, please contact the case handler. Their contact details are provided at the top of the letter we sent to you." class="input-label">Unfortunately the details you have provided does not match the information that we hold on file for you. Please click on "try again" and enter the correct details. If you need to speak to us, please contact the case handler. Their contact details are provided at the top of the letter we sent to you.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" (click)="refreshPage();" tabindex="0">Try again</button>
                    </div>
                </jw-modal>
                <jw-modal id="bad-request-modal" url="text">
                    <div>
                        <label style="margin-bottom: 50px;" aria-label="Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused." class="input-label">Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" (click)="closeModal('bad-request-modal');" tabindex="0">Close</button>
                    </div>
                </jw-modal>
                <jw-modal id="recaptcha-failed-modal" url="text">
                    <div>
                        <label style="margin-bottom: 50px;" aria-label="Unfortunately, the network you are using to reach Heart is not able to validate your information. Please try again on another device or network. Apologies for any inconvenience caused." class="input-label">Unfortunately, the network you are using to reach Heart is not able to validate your information. Please try again on another device or network. Apologies for any inconvenience caused.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" (click)="closeModal('recaptcha-failed-modal');" tabindex="0">Close</button>
                    </div>
                </jw-modal>
            </div>
        </div>
    </div>
</div>
    </div>
    
</div>
</div>  


