import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css']
})
export class DateSelectorComponent implements OnInit {
  dateToday:string;
  @Input() options!: string;
  @Output() answered = new EventEmitter<any>();
  answer="";
  constructor() {
    let d = new Date();
    let dd = String(d.getDate()).padStart(2,'0');
    let mm = String(d.getMonth()+1).padStart(2,'0');
    let yyyy = d.getFullYear();
    this.dateToday = [yyyy,mm,dd].join('-');
   }

  ngOnInit(): void {
  }

  answerQuestion(nextQuestion:string,ans:string){
    let nextQ = parseInt(nextQuestion);
    this.answered.emit({nextQ,ans});
  }

}
