import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  _httpService : HttpService;
  constructor(private httpService: HttpService) {
    this._httpService = httpService;
   }

  ngOnInit(): void {
  }

}
