<div style="padding-top: 100px">
<div class="heartTitleFont">
    <img id="logo-heart" src="../../assets/images/Heart-logo2.png" style="margin: 10px 20px 10px 0px;"/>
</div>
<div class="heart-title" >
    <br/>
    <label aria-label="HF’s Eco-friendly Automated Reporting Tool">HF’s Eco-friendly Automated Reporting Tool</label>
    <br/>
    <br/>
</div>

<div class="central">
    <div class="container">
        <div>
            <div class="body-container">
                <br/>
                <label class="input-label-modal" aria-label="Horwich Farrelly have been appointed by your insurers as they had to pay some money out following an accident you were involved in. They want to get that money back from the other driver’s insurers and, to do that, they may have to issue proceedings against the other driver. Before we can do anything else, we need to get a few more pieces of information from you. This process shouldn’t take any longer than about 5 minutes to complete and.">Horwich Farrelly have been appointed by your insurers as they had to pay some money out following an accident you were involved in. They want to get that money back from the other driver’s insurers and, to do that, they may have to issue proceedings against the other driver. Before we can do anything else, we need to get a few more pieces of information from you. This process shouldn’t take any longer than about 5 minutes to complete.</label>
                <br/>
                <br/>
                <label class="input-label-modal" aria-label="By using this service, not only are you able to provide us with the details we need to progress this claim in a convenient way, you’re also helping the environment by reducing the amount of paper we use.">By using this service, not only are you able to provide us with the details we need to progress this claim in a convenient way, you’re also helping the environment by reducing the amount of paper we use.    </label>
                <br/>
                <br/>
                <label class="input-label-modal" aria-label="Before we get started, would you like to watch a video or read further information that explains more about who we are?">Before we get started, would you like to watch a video or read further information that explains more about who we are?</label>
                <br/>
                <br/>
                <br/>
            </div>
            
            <div class="navigation-container">
                <app-video-buttons id="initial-modal-video" (noThankYou)="navigateToValidationForm()" [modal]="true" proceedButtonText="Let's get started" writtenExplanation="Following your accident you will have reported the matter to your motor insurers and they will either have paid for your vehicle to be repaired or in the event your vehicle was beyond economical repair, you will have been paid the pre-accident value of your vehicle.  As you reported the accident was not your fault your motor insurers will have attempted to recover the repair costs from the fault driver and/or his/her motor insurers without success.  The reason they have so far been unsuccessful will usually be down to the fact that the other driver has not reported the accident, or they have reported the accident but do not accept liability or, the other insurers could simply have a backlog which has meant they have not dealt with the request for reimbursement.  In any event we have been instructed by your insurers to recover their outlay and as part of the claim we are happy to include your uninsured losses at no cost to yourself." url="https://player.vimeo.com/video/501185226?h=1ee025936a&autoplay=1"></app-video-buttons>
            </div>

            <br/>
            <br/>
        </div>
    </div>
    
</div>
</div>