import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.css']
})
export class CompleteComponent implements OnInit {

  constructor(private HttpService: HttpService, private locationStrategy: LocationStrategy) {
    history.pushState(null, "null", window.location.href);  
    this.locationStrategy.onPopState(() => {
      history.pushState(null, "null", window.location.href);
    })
  }

  ngOnInit(): void {
    this.HttpService.isNotWelcomePage = true;
  }

}
