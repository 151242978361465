<div style="padding-bottom: 20px;">
    <form>
    <div class="row justify-content-center">
        <div class="col-6">
            <input style="width:100%" type="text" placeholder="Pick one" #matInput [formControl]="autoCompleteControl" #t [matAutocomplete]="auto" [(ngModel)]="answer" (keyup)="answerQuestion()" >
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let option of filteredOptions | async;" [value]="option" >
                    {{option.split('¦')[0]}}
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>  
    </form>
    <div *ngIf="matInput.value == ''" style="text-align: left;">
        <div class="invalid" >
            This field is required
        </div> 
    </div> 
    <div *ngIf="matInput.value != '' && availableValues.includes(matInput.value) == false" style="text-align: left;">
        <div class="invalid" >
            Invalid value selected
        </div> 
    </div> 
</div>