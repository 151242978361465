import { Component, OnInit, Input, Output, EventEmitter, ApplicationRef } from '@angular/core';


@Component({
  selector: 'app-dropdown-sequence',
  templateUrl: './dropdown-sequence.component.html',
  styleUrls: ['./dropdown-sequence.component.css']
})
export class DropdownSequenceComponent implements OnInit {

  @Input() questionList!: string[][];
  @Input() otherValueAnswer!: string;
  sequence = new Array();
  answers!: string[][];
  @Output() answered = new EventEmitter<any>();
  sequenceIndex: number = 0;

  constructor(private appRef: ApplicationRef) {
    this.answers = [];
    this.answers[0] = [];

  }

  ngOnInit(): void {
    this.sequence.push(this.questionList);
    for(var i=0; i<this.questionList.length; i++){
      this.answers[0][i]="";
    }
  }

  answerQuestion(questionNumber:number,nextQ:number,answer:string, sequenceIndex: number){
    this.answers[sequenceIndex][questionNumber] = answer;
    var ans = this.answers;
    this.appRef.tick();
    this.answered.emit({nextQ,ans});
  }


  createSequence(){
    this.sequenceIndex++;
    this.answers[this.sequenceIndex] = [];
    for(var i=0; i<this.questionList.length; i++){
      this.answers[this.sequenceIndex][i]="";
    }
    var anotherQuestionList = this.questionList.slice();
    this.sequence.push(anotherQuestionList);
  }

  removeSequence(index: number){
    this.sequence.splice(index, 1);
    this.answers.splice(index,1);
    this.sequenceIndex--;
  } 
}
