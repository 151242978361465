<input type="text" maxlength="250" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$" ngModel #emailref="ngModel" [(ngModel)]="answer" (keyup)="answerQuestion(nextQuestion,answer)" (keydown.enter)="answerQuestion(nextQuestion,answer)" (focusout)="answerQuestion(nextQuestion,answer)" placeholder="Type here..." tabindex="0">
<div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
    <div class="invalid" [hidden]="!emailref.errors?.pattern">
        Invalid Email
    </div> 
</div> 
<div *ngIf="this.answer.length == 0" style="text-align: left;">
    <div class="invalid">
        This field is required
    </div> 
</div> 


