<div *ngFor="let questionList of sequence; index as i;">
    <div style="text-align: left;">
  
    <div class="col-12" >
        <p class="" attr.aria-label="{{questionList[0][0]}}">{{questionList[0][0]}}</p>
     </div>
    <div class="col-12" >
        <app-autocomplete-single-dropdown (answered)="answerQuestion(0,$event.nextQ,$event.ans, i)"  [options]="questionList[0][2]"></app-autocomplete-single-dropdown>
    </div>
    <div *ngIf="this.answers[i][0]===this.otherValueAnswer">
        <div class="col-12">
            <p class="" attr.aria-label="{{questionList[1][0]}}">{{questionList[1][0]}}</p>
         </div>
         <div class="col-12">
            <app-text-field (answered)="answerQuestion(1,$event.nextQ,$event.ans, i)" [options]="questionList[1][2]" ></app-text-field>
        </div>
    </div>
    <div class="col-12">
        <p class="" attr.aria-label="{{questionList[2][0]}}">{{questionList[2][0]}}</p>
     </div>
     <div class="col-12">
        <app-number-text-field  (answered)="answerQuestion(2,$event.nextQ,$event.ans, i)" [options]="questionList[2][2]" ></app-number-text-field>
    </div>
    <div *ngIf="sequence.length>1">
        <div class="col-12">
            <label style="display:inline-block; text-decoration: none; padding-top: 5px; color: #c30101;">Delete</label>
            <a class="delete-button title-font dropbtn" (click)="removeSequence(i)" aria-label="Delete expense type" style="display:inline-block; text-decoration: none;" tabindex="0"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
            </a>
        </div>
    </div> 
</div>
</div>

<div style = "padding-bottom: 20px;">
    <label style="display:inline-block; text-decoration: none; color: #006400;">Add another</label>
    <a class="add-button title-font dropbtn" (click)="createSequence()" aria-label="Add another expense type" style="display:inline-block; text-decoration: none;" tabindex="0"> 
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
          </svg>
    </a>
</div>
