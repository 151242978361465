import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { UserValidationService} from '../../services/user-validation.service';
import { ModalService } from '../_modal/modal.service';
import { CheckpointsService } from '../../services/checkpoints.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css']
})
export class WelcomePageComponent implements OnInit {

  isSubmitted = false;

  constructor(private httpService: HttpService, private router: Router,private modalService: ModalService, private checkpointsService: CheckpointsService) {
    httpService.isNotWelcomePage = false;
   }

  ngOnInit(): void {
    this.checkpointsService.setTitleId(1);
  }

  navigateToValidationForm(){
    this.isSubmitted = true;
    this.router.navigateByUrl('user-validation');
    this.isSubmitted = false;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
