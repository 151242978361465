import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-button-selection',
  templateUrl: './button-selection.component.html',
  styleUrls: ['./button-selection.component.css']
})
export class ButtonSelectionComponent implements OnInit {
  @Input() options!: string;
  @Input() currentQuestion!: number;
  optionsArray!:string[];
  nextQuestion!:number;
  hasTooltips:boolean = false;

  @Output() answered = new EventEmitter<any>();
  constructor() {
    
   }

  ngOnInit(): void {
    this.optionsArray = this.options.split('|');
    this.hasTooltips = this.optionsArray[1].split('¦').length == 3;
  }

  answerQuestion(nextQuestion:string,ans:string){
    let nextQ = parseInt(nextQuestion)
    this.answered.emit({nextQ,ans});
  }

}
