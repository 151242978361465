import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { FormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
  selector: 'app-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteDropdownComponent implements OnInit {

  autoCompleteControl = new FormControl();
  @Input() options!: string;
  optionsArray!:string[];
  filteredOptions!: Observable<string[]>;
  textEntered="";
  nextQuestion!:string;
  selectedOptions: string[] = [];
  selectable = true;
  removable = true;
  separatorKeyCodes: number[] = [ENTER, COMMA];
  
  @Output() answered = new EventEmitter<any>();
  @ViewChild('dropdownInput') dropdownInput!: ElementRef<HTMLInputElement>;

  constructor() { 
    
  }

  ngOnInit(): void {
    this.optionsArray = this.options.split('|');
    this.nextQuestion = this.optionsArray[0].split('¦')[1];
    this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(startWith(''),map(value => value ? this._filter(value) : this.optionsArray.slice()));
  }

  private _filter(value:string):string[]{
    const filterValue = value.toLowerCase();

    return this.optionsArray.filter(option => option.toLowerCase().includes(filterValue));
  }

  answerQuestion(nextQuestion:string,ans:string){
    if(!this.selectedOptions.includes(ans)){
      let nextQ = parseInt(nextQuestion);
      this.selectedOptions.push(ans);
      ans = this.selectedOptions.join(', ');
      this.dropdownInput.nativeElement.value = '';
      this.autoCompleteControl.setValue(null);
      this.answered.emit({nextQ,ans});
    }
  }

  remove(option: string): void {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      let nextQ = this.optionsArray.find(x => x.includes(this.selectedOptions[0]))?.split('¦')[1];
      let ans = this.selectedOptions.join(', '); 
      this.answered.emit({nextQ,ans});
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let answer = event.option.value.split('¦')[0];
    let nextQuestion = event.option.value.split('¦')[1];
    this.answerQuestion(nextQuestion, answer);
    this.dropdownInput.nativeElement.value = '';
    this.autoCompleteControl.setValue(null);
  }
}
