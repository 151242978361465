<div class="container">
    <div class="row justify-content-center">
        <div class="col-lg" *ngIf="options.split('|').length < 3"></div>
        <div class="col-lg" *ngIf="options.split('|').length < 3"></div>
        <div class="col-sm" *ngFor="let option of options.split('|');" style="text-align: center; flex: 7 0 0%;" >
            <a type="button" 
            [attr.aria-label]="(this.hasTooltips === true) ? option.split('¦')[2] : option.split('¦')[0]" 
            [id]="'question-'+currentQuestion+'-answer-'+option.split('¦')[0]" 
            class="watch-video-button title-font dropbtn buttontooltip" 
            (click)="answerQuestion(option.split('¦')[1],option.split('¦')[0])" 
            style="text-decoration: none;" 
            tabindex="0" 
            ngbTooltip="{{option.split('¦')[2]}}"
            placement="top"
            title="{{option.split('¦')[2]}}" >
                {{option.split('¦')[0]}}
            </a>
        </div>  
        <div class="col-lg" *ngIf="options.split('|').length < 3"></div>
        <div class="col-lg" *ngIf="options.split('|').length < 3"></div>
    </div>
</div>
