import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CheckpointsService {

  public titleId =1;
  constructor() {
    
  }
  
  public getTitleId(): any {
    const idObservable = new Observable(observer => { 
      
        observer.next(this.titleId)});
    return idObservable;
  }

  public setTitleId(id:number){
    this.titleId=id;
  }

  public getCurrentProgress(){
    if(this.titleId == 1){
      return "0%";
    }
    return ((this.titleId)/9*100).toFixed(0).toString() + "%";
  }
}
