import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  isNotWelcomePage:boolean;

  constructor(private http: HttpClient) {
    this.isNotWelcomePage=false;
   }

  validateUser(body: string){
    return this.http.post(environment.UserValidationLookupUrl, body, {responseType:'text' as 'json', observe: 'response'});
  }

  sendMail(body: string){

    return this.http.post(environment.MailAssistantUrl, body, {responseType:'text' as 'json', observe: 'response'});

  }

  updateCase(body: string){

    return this.http.post(environment.UpdateHeartCaseUrl, body, {responseType:'text' as 'json', observe: 'response'});

  }
}
