<mat-form-field appearance="fill">
    <div class="row justify-content-center" >
        <div>
            <mat-chip-list  #chipList aria-label="Options selection">
                <mat-chip
                  *ngFor="let option of selectedOptions"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(option)">
                  {{option}}
                  <button matChipRemove *ngIf="removable">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input placeholder="Select options..." [formControl]="autoCompleteControl"  #dropdownInput  [matAutocomplete]="auto" [matChipInputFor]="chipList" >
              </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let option of filteredOptions | async;" [value]="option" >
                    {{option.split('¦')[0]}}
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>  
</mat-form-field>


