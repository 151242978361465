<div style="padding-top: 30px; padding-bottom: 30px;">
    <div class="explanation-container" [ngStyle]="{'width': displayText ? '25%' : '100%'}">
        <div *ngIf="displayText" class="explanation-text">
            For video or written explanation click below
        </div>
        <div>
            <a class="watch-video-button title-font dropbtn" *ngIf="noVideoRequired" aria-label="Video Explanation" (click)="openModal('video'+id)" style="display:inline-block; text-decoration: none;" tabindex="0"><i class="bi bi-camera-video"></i></a>
            <a class="watch-video-button title-font dropbtn" aria-label="Written Explanation" (click)="openModal('written'+id)" style="display:inline-block; text-decoration: none;" tabindex="0"><i class="bi bi-book"></i></a>        
        </div>
    </div>
    <div *ngIf="noThankYouRequired" class="proceed-container">
        <a class="proceed-button title-font dropbtn" aria-label="No thank you" (click)="noThankYouClicked()" style="text-decoration: none; " tabindex="0">{{this.proceedButtonText}} <i *ngIf="this.downArrow" class="bi bi-chevron-down"></i><i *ngIf="this.downArrow == false" class="bi bi-chevron-right"></i></a>
    </div>
</div>
<jw-modal [id]="'video'+id" [url]="url" *ngIf="noVideoRequired">
    <button class="video-modal-button title-font dropbtn" aria-label="Close" (click)="closeModal('video'+id);" tabindex="0">Close</button>
</jw-modal>
<jw-modal [id]="'written'+id" url="text" class="written-explanation-modal">
    <label class="input-label" attr.aria-label="{{writtenExplanation}}" >{{writtenExplanation}}</label>
    <br/>
    <button class="video-modal-button title-font dropbtn" aria-label="Close" (click)="closeModal('written'+id);" tabindex="0">Close</button>
</jw-modal>
