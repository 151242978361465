import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-text-field-nonrequired',
  templateUrl: './text-field-nonrequired.component.html',
  styleUrls: ['./text-field-nonrequired.component.css']
})
export class TextFieldNonRequiredComponent implements OnInit {

  @Input() options!: string;
  placeholder!:string;
  nextQuestion!:number;
  answer="";

  @Output() answered = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.placeholder = this.options.split('¦')[0]
    let nextQuestionString = this.options.split('¦')[1];
    this.nextQuestion = parseInt(nextQuestionString);
  }

  answerQuestion(nextQ:number,ans:string){
    this.answered.emit({nextQ,ans});
  }

}
