import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidationFormComponent } from './components/validation-form/validation-form.component';
import { QuestionFormComponent } from './components/question-form/question-form.component';
import { WrongRouteComponent } from './components/wrong-route/wrong-route.component';
import {UserValidationGuard} from './guards/user-validation.guard';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CompleteComponent } from './components/complete/complete.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';

const routes: Routes = [
  { path: 'welcome', component: WelcomePageComponent },
  { path: 'user-validation', component: ValidationFormComponent },
{ path: 'question-form', component: QuestionFormComponent, canActivate: [UserValidationGuard] },
{ path: 'submission-complete', component: CompleteComponent, canActivate: [UserValidationGuard] },
{ path: '',   redirectTo: '/welcome', pathMatch: 'full' },
{ path: '**', component: WrongRouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
