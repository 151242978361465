<div class="sticky-top topbar">
    <div class="row justify-content-center">
        <nav class="col-12" style="background-color: rgba(24, 65, 75, 1);">
            <div class="heartTitleFont">
                <img id="logo-hf" src="../../assets/images/logo_HF.png" style="margin: 10px 20px 10px 20px; width: auto;"/>
            </div>
            <div *ngIf="_httpService.isNotWelcomePage"  class="heartTitleFont">
                <img id="logo-heart" src="../../assets/images/Heart-logo2.png" style="margin: 10px 20px 10px 0px;"/>
            </div>
        </nav>
    </div>
    <div *ngIf="_httpService.isNotWelcomePage" class="row justify-content-center">
        <progress-bar class="col-12"></progress-bar>
    </div>
</div>

