<div style="padding-bottom: 20px;">
<input type="text" maxlength="250" pattern="[0-9]*" ngModel #numberRef="ngModel"  [(ngModel)]="answer" (keyup)="answerQuestion(nextQuestion,answer)" (keydown.enter)="answerQuestion(nextQuestion,answer)" (focusout)="answerQuestion(nextQuestion,answer)" [placeholder]="placeholder" tabindex="0">
<div *ngIf="numberRef.errors &&(numberRef.touched || numberRef.dirty)">
    <div class="invalid" [hidden]="!numberRef.errors?.pattern">
        Invalid number
    </div> 
</div> 
<div *ngIf="answer.length == 0" style="text-align: left;">
    <div class="invalid">
        This field is required
    </div> 
</div> 
</div>

