import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { FormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
  selector: 'app-autocomplete-single-dropdown',
  templateUrl: './autocomplete-single-dropdown.component.html',
  styleUrls: ['./autocomplete-single-dropdown.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteSingleDropdownComponent implements OnInit {

 
  autoCompleteControl = new FormControl();
  @Input() options!: string;
  @Output() answered = new EventEmitter<any>();
  optionsArray!:string[];
  availableValues!:string[];
  filteredOptions!: Observable<string[]>;
  textEntered="";
  answer: string = "";
  nextQuestion!:string;
  @ViewChild('matInput') matInput!: ElementRef<HTMLInputElement>;

  constructor() { 
    
  }

  ngOnInit(): void {
    this.optionsArray = this.options.split('|');
    this.nextQuestion = this.optionsArray[0].split('¦')[1];
    this.availableValues = this.optionsArray.map(x => x.substring(0,x.indexOf('¦')));
    this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(startWith(''),map(value => value ? this._filter(value) : this.optionsArray.slice()));
  }

  private _filter(value:string):string[]{
    const filterValue = value.toLowerCase();

    return this.optionsArray.filter(option => option.toLowerCase().includes(filterValue));
  }

  answerQuestion(){
    let nextQ = parseInt(this.nextQuestion);
    let ans = this.answer;
    this.answered.emit({nextQ,ans});
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.answer = event.option.value.split('¦')[0];
    this.nextQuestion = event.option.value.split('¦')[1];
    this.answerQuestion();
    this.matInput.nativeElement.value = this.answer;
  }
}
