import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { UserValidationService} from '../../services/user-validation.service';
import { ModalService } from '../_modal/modal.service';
import { CheckpointsService } from '../../services/checkpoints.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-validation-form',
  templateUrl: './validation-form.component.html',
  styleUrls: ['./validation-form.component.css']
})
export class ValidationFormComponent implements OnInit {
  jsonResult = "";
  jsonUser = "";
  validUser = false;
  exampleDropdown = ["option 1","option 2","option 3","option 4","option 5","option 6",];

  questionList = [
    ["Your name","text","Type here...¦1"],
    ["Your reference number","text","Type here...¦2"],
    ["Your vehicle registration number","text","Type here...¦3"]
  ];

  textValues = new Array(3).fill("");

  answersList = new Array(3).fill("");
  displayedQuestions = new Set([0]);
  isSubmitted = false;

  constructor(private HttpService: HttpService,private router: Router,private userValidationService: UserValidationService,private modalService: ModalService, private checkpointsService: CheckpointsService, private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {
    this.checkpointsService.setTitleId(1);
    this.HttpService.isNotWelcomePage = true;
  }

  submitForm() {
    this.isSubmitted = true;
    document.body.classList.add('waiting');

    console.log(`Recaptcha v3 execution requested...`);
    this.userValidationService.captchaResponse = '';
    let recaptchaSubscription = this.recaptchaV3Service.execute('').subscribe(
      token => {
        console.log(`Recaptcha v3 token received`);
        this.userValidationService.captchaResponse = token;
        this.validateUser();
      },
      error => {
        document.body.classList.remove('waiting');
        console.log(`Recaptcha v3 error:`, error);
        this.openModal('recaptcha-failed-modal');
      }
    );
    setTimeout(() => {
      recaptchaSubscription.unsubscribe();
      if( this.userValidationService.captchaResponse === '')
        this.openModal('recaptcha-failed-modal');
    }, 10000);
    document.body.classList.remove('waiting');
  }

  validateUser(){

    if(this.userValidationService.captchaResponse != ''){
      this.userValidationService.validationQuestions = this.questionList;
      this.userValidationService.validationAnswers = this.answersList;
        
        setTimeout(()=> {this.userValidationService.ValidateUser().subscribe(response => {
          if(response.status == 200){
            var isValidated = false;
            if(response.body){
              isValidated = response.body.toString().includes("true");
            }
            this.userValidationService.userValidated = isValidated;
            if(this.userValidationService.userValidated){
              this.router.navigateByUrl('question-form');
            }
            else{
              this.openModal('failed-to-authenticate-modal');
            }
          }
          else if(response.status == 401){
            this.openModal('recaptcha-failed-modal');
          }
          else{
            this.openModal('bad-request-modal');
          }
          this.isSubmitted = false;
        },
        err => {
         console.log(err);
         this.openModal('bad-request-modal');
         this.isSubmitted = false;
       })});
    }
    
  }

  hasAnswers() : boolean{
    let isValid = this.answersList.every(x => x.toString() !== "");
    if(isValid) document.getElementById("submit-validation-button")?.scrollIntoView();
    return isValid;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  refreshPage(){
    window.location.reload();
  }

}
