import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule } from './components/_modal';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ValidationFormComponent } from './components/validation-form/validation-form.component';
import { QuestionFormComponent } from './components/question-form/question-form.component';
import {UserValidationGuard} from './guards/user-validation.guard';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AutocompleteDropdownComponent } from './components/autocomplete-dropdown/autocomplete-dropdown.component';
import { AutocompleteSingleDropdownComponent } from './components/autocomplete-single-dropdown/autocomplete-single-dropdown.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { TextFieldNonRequiredComponent } from './components/text-field-nonrequired/text-field-nonrequired.component';
import { NumberTextFieldComponent } from './components/number-text-field/number-text-field.component';
import { BigTextFieldComponent } from './components/big-text-field/big-text-field.component';
import { ButtonSelectionComponent } from './components/button-selection/button-selection.component';
import { CheckboxSelectorComponent } from './components/checkbox-selector/checkbox-selector.component';
import { ToolTipComponent } from './components/tool-tip/tool-tip.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { EmailFieldComponent } from './components/email-field/email-field.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { VideoButtonsComponent } from './components/video-buttons/video-buttons.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { WrongRouteComponent } from './components/wrong-route/wrong-route.component';
import { CompleteComponent } from './components/complete/complete.component';
import { FooterComponent } from './components/footer/footer.component';
import { DropdownSequenceComponent } from './components/dropdown-sequence/dropdown-sequence.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { environment } from 'src/environments/environment';
import { SortCodeFieldComponent } from './components/sort-code-field/sort-code-field.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomePageComponent,
    ValidationFormComponent,
    QuestionFormComponent,
    AutocompleteDropdownComponent,
    AutocompleteSingleDropdownComponent,
    TextFieldComponent,
    TextFieldNonRequiredComponent,
    NumberTextFieldComponent,
    BigTextFieldComponent,
    ButtonSelectionComponent,
    CheckboxSelectorComponent,
    ToolTipComponent,
    DateSelectorComponent,
    EmailFieldComponent,
    TopBarComponent,
    VideoButtonsComponent,
    ProgressBarComponent,
    WrongRouteComponent,
    CompleteComponent,
    FooterComponent,
    DropdownSequenceComponent,
    SortCodeFieldComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModalModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    MatChipsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    NgbModule,
    RecaptchaV3Module 
  ],
  providers: [
    UserValidationGuard,     
    {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.captchaKey ,
    }
  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
